import {useState, useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';

import Home from './pages/Home';
import NotFound from './pages/NotFound';

import Modal from './components/Modal';
import AppContext from './services/AppContext';
import DigitalSignature from './pages/DigitalSignature';

function App() {
	//for framer motion animation attached to react router
	const location = useLocation();

	//setting the Modal context here
	const [showModal, setShowModal] = useState(false);
	const modal = {showModal, setShowModal};

	//setting the scroll context here
	const [scrollPosition, setScrollPosition] = useState(0);
	const scroll = { scrollPosition, setScrollPosition };

	// const [elements, setElements] = useState({});
	// const refs = { elements, setElements };

	useEffect(() => {
		window.addEventListener('scroll', () =>
			setScrollPosition(window.pageYOffset)
		);
	}, []);

	useEffect(() => {
		if (location.pathname.length > 1) window.scrollTo(0, 0);
	}, [location]);

	//final context value
	const value = {modal, scroll};

	return (
		<AnimatePresence>
			<AppContext.Provider value={value}>
				<main
					className={
						modal.showModal
							? 'overflow-hidden font-body text-purple-400 text-base '
							: 'font-body text-purple-400 text-base '
					}
					id='pageTop'>
					<Routes location={location} key={location.pathname}>
						<Route path='/' element={<Home />} />
						<Route path='/email-sign' element={<DigitalSignature />} />
						{/* <Route path='/contact' element={<Contact />} /> */}
						<Route path='*' element={<NotFound />} />
					</Routes>
				</main>
				<Modal />
			</AppContext.Provider>
		</AnimatePresence>
	);
}

export default App;
