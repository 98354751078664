import { useState } from 'react';
import H2 from '../components/H2';
import SubHeading from '../components/SubHeading';
import Carousel from './../components/Carousel';

function Features({heading, subHeading, closingPhrase, children, data}) {
	const [addclass, setAddClass] = useState("");
	return (
		<section className='lg:flex pl-7 pr-0 xl:px-0'>
			{/* <article className='lg:w-2/4 relative after:content-none lg:after:content-[""] after:block after:h-full after:w-10 after:absolute after:top-0 after:-right-10 after:z-20 after:bg-gradient-to-r after:from-white to-transparent pr-7 lg:pr-0'> */}
			<article className='lg:w-2/4 relative pr-7 lg:pr-12 '>
				<div className='f-wrap lg:max-w-[600px]'>
				<H2 text={heading} className='mb-7' />
				<SubHeading
					text={subHeading}
					className='font-bold mb-7 lg:mb-10 text-xl'
					size='lg'
				/>
				<p className='mb-7'>{children}</p>
				<SubHeading
					text={closingPhrase}
					className='font-bold text-brand mb-10 text-xl'
					size='lg'
				/>
				</div>
			</article>
			<article id="slide_wrap" className={`lg:w-2/4 lg:absolute right-0 overflow-hidden ${addclass} `}>
				<Carousel data={data} setAddClass={setAddClass}/>
			</article>
		</section>
	);
}

export default Features;
