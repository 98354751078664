function Heading({text, theme, className}) {
	let textColor = theme === 'light' ? 'text-white' : 'text-brand';
	return (
		<h1
			className={
				className +
				' font-h1 text-3.5xl lg:text-4xl xl:text-5xl mb-2 lg:leading-tight ' +
				textColor
			}>
			{text}
		</h1>
	);
}

export default Heading;
