import { useContext } from 'react'
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';

import Button from './Button';
import LinkBtn from './LinkBtn';
import Logo from './Logo';
import cross from './../assets/icons/cross.svg';
import menuPattern from './../assets/icons/menuPattern.svg';

import scrollIntoView from '../utils/scrollIntoView';
import AppContext from '../services/AppContext';

function Menu() {
	const {
		modal: {setShowModal},
	} = useContext(AppContext);

	return (
		<div className='h-screen overflow-hidden relative'>
			{/* <motion.img
				className='absolute top-[-2rem] w-full'
				src={menuPattern}
				alt=''
				initial={{opacity: 0.4}}
				exit={{opacity: 0}}
			/> */}
			<div className='relative w-auto my-7 mx-auto px-7 md:p-10 md:my-0'>
				<div className='flex items-center'>
					<Link className='w-52' onClick={() => setShowModal('menu')}>
						<Logo className='w-52 lg:w-auto' />
					</Link>
					<Button
						to='/'
						theme='light'
						className='ml-auto px-2 w-[36px] flex'
						onClick={() => setShowModal(false)}>
						<img src={cross} alt='' />
					</Button>
				</div>
				<ul className='flex flex-col text-center mt-24'>
					<li className='mb-7'>
						<LinkBtn
							text='Sellers'
							theme='light'
							size='large'
							onClick={() => {
								setShowModal(false);
								scrollIntoView('sellers');
							}}
						/>
					</li>
					<li className='mb-7'>
						<LinkBtn
							text='Channels'
							theme='light'
							size='large'
							onClick={() => {
								setShowModal(false);
								scrollIntoView('channels');
							}}
						/>
					</li>
					<li className='mb-7'>
						<LinkBtn
							text='About'
							theme='light'
							size='large'
							onClick={() => {
								setShowModal(false);
								scrollIntoView('about');
							}}
						/>
					</li>
					<li className='mb-7'>
						<LinkBtn
							text='Contact'
							size='large'
							theme='light'
							onClick={() => setShowModal('contact')}
						/>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Menu;
