function H2({text, className}) {
	return (
		<h2
			className={
				className +
				' font-heading text-3.5xl lg:text-4xl mb-2 lg:mb-10'
			}>
			{text}
		</h2>
	);
}

export default H2;
