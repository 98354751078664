import { useContext } from 'react'
import Heading from './../components/Heading';
import SubHeading from './../components/SubHeading';
import ShortFeature from './../components/ShortFeature';

import joinIcon from './../assets/icons/joinIcon.svg';
import splitIcon from './../assets/icons/splitIcon.svg';
import checkIcon from './../assets/icons/checkIcon.svg';
import scrollDownArrow from './../assets/icons/scrolldownarrow.svg';

import AppContext from '../services/AppContext';

function HomeHero() {
	const {
		scroll: {scrollPosition},
	} = useContext(AppContext);

	//setting up different style based on scroll position
	let scrollArrow;
	if (scrollPosition > 10) {
		scrollArrow = 'hidden';
	}
	return (
		<div className='relative z-20 lg:mt-0'>
			<div className="ff-hero relative flex items-center md:py-40 pt-24 pb-20">
				<article className='art-heading'>
					<Heading
						text={['One connection', <br />, 'A world of events']}
					/>
					<br />
					<SubHeading
						text='Tixstock’s market-leading technology eliminates inefficient, dated and fragmented back-end processes with a simple-to-use suite of tools.'
						className='text-white  max-w-xl'
					/>
				</article>
				<a
					href='#techFor'
					className={
						scrollArrow +
						' animate-bounce text-center w-full block absolute bottom-20 md:bottom-20 left-0 right-0 '
					}>
					<img
						className='mx-auto'
						src={scrollDownArrow}
						alt='scroll down'
					/>
				</a>
			</div>

			<div className='mt-0 md:mt-24 lg:mt-36'>
				{/** 3 items section scroll point */}
				<div id='techFor' className='w-full h-[120px] '>
					&nbsp;
				</div>
				<section className='md:flex justify-between flex-wrap lg:flex-nowrap'>
					<ShortFeature icon={splitIcon} heading='Tech for sellers'>
						<p className='text-white mb-10 lg:mb-0 subHeading font-subHeading'>
							Generate maximum returns with a streamlined,
							efficient inventory management suite
						</p>
					</ShortFeature>
					<ShortFeature icon={joinIcon} heading='Tech for channels'>
						<p className='text-white mb-10 lg:mb-0 font-subHeading'>
							Offer immediate access to a qualified, aggregated
							feed of vetted inventory from global sellers
						</p>
					</ShortFeature>
					<ShortFeature icon={checkIcon} heading='Tech for everyone'>
						<p className='text-white mb-10 lg:mb-0 font-subHeading'>
							Innovative technology and a powerful API takes care
							of time-consuming back-end administration and
							fulfilment
						</p>
					</ShortFeature>
				</section>
			</div>
		</div>
	);
}

export default HomeHero;
