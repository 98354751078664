import {Link} from 'react-router-dom';

function Button({text, to, theme, className, onClick, children}) {
	let themeText = theme === 'light' ? 'text-white' : ' border-purple-400';

	return (
		<Link
			to={to}
			onClick={onClick}
			className={
				className +
				' ' +
				themeText +
				' rounded border font-heading text-xl px-5 py-1 h-[36px] hover:bg-purple-50 hover:text-white hover:border-purple-50'
			}>
			{children ? children : text}
		</Link>
	);
}

export default Button;
