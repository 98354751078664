import {useContext, useRef} from 'react';
import {motion} from 'framer-motion';
import Navbar from './../layouts/Navbar';
import Footer from './../layouts/Footer';
import Features from './../layouts/Features';
import HomeHero from './../layouts/HomeHero';
import FullBackground from './../layouts/FullBackground';
import HeroPattern from './../components/HeroPattern';
import HeroPatternMobile from './../components/HeroPatternMobile';

// import EarthVideo from './../assets/videos/earth.mp4';
import {sellers, channels} from './../services/featureData';
import AppContext from '../services/AppContext';

function Home() {
	let width = window.innerWidth;

	// setup for header class
	let headerClass = 'border-b-transparent z-50 ';
	const {
		scroll: {scrollPosition},
	} = useContext(AppContext);
	const sallerRef = useRef();
	const globeRef = useRef();

	//setting up different style based on scroll position
	if (scrollPosition > 10) {
		headerClass = 'z-50 2xl:py-10 scrolled ';
		if (scrollPosition > sallerRef.current.offsetTop - 60) {
			headerClass = 'z-50 2xl:py-10 scrolled custom-invert ';
		}
		if (scrollPosition > globeRef.current.offsetTop - 60) {
			headerClass = 'z-50 2xl:py-10 scrolled ';
		}
	}

	return (
		<div>
			<div className='bg-purple-200 overflow-hidden relative md:pb-14 pb-9'>
				{width > 767 ? (
					<div className='bottom-0 left-0 w-full h-full absolute pattern-wrap'>
					{/* <div className='pattern-wrap'> */}
						<HeroPattern className='absolute bottom-0 z-10 w-full hero-pattern  ' />
						{/* <HeroPattern className=' hero-pattern  ' /> */}
					</div>
				) : (
					<div className='bottom-0 left-0 w-full h-full absolute pattern-wrap'>
					{/* <div className='pattern-wrap'> */}
					<HeroPatternMobile className='absolute bottom-0 z-10 w-full hero-pattern ' />
					{/* <HeroPatternMobile className='hero-pattern ' /> */}
					</div>
				)}
				<section
					className={
						headerClass +
						'p-7 md:p-10 2xl:p-20  mx-auto fixed top-0 left-0 right-0 transition-all ease-in-out duration-200 bg-transparent header-main'
					}>
					<Navbar />
				</section>
				<motion.section
					className='px-7 lg:px-20  mx-auto lg:pb-40 hero-with-motion'
					exit={{opacity: 0}}
					transition={{duration: 0.5}}>
					<article className='max-w-6xl mx-auto'>
						<HomeHero />
					</article>
				</motion.section>
			</div>

			<section
				className='pt-22 pb-16 lg:pt-44 lg:pb-32 mx-auto'
				id='sellers'
				ref={sallerRef}>
				<article className='max-w-6xl mx-auto'>
					<Features
						heading='Tech for sellers'
						subHeading='Maximise your inventory’s impact with Tixstock’s smart distribution technology.'
						closingPhrase='The fastest, most reliable way to put your inventory infront of an engaged, sales-ready audience.'
						data={sellers}>
						Tixstock’s industry-leading distribution technology
						makes it easier than ever before for you to manage your
						inventory. Swap your inefficient, ineffective and
						unprofitable back-end business processes for real time
						inventory management.
						<br />
						<br />
						Market efficiency is the key to increasing your profits,
						and Tixstock’s inventory management suite delivers
						increased efficiency.
					</Features>
				</article>
			</section>

			<article className='max-w-6xl mx-auto xl:px-0 px-7' id='channels'>
				<div className='border-b border-gray-200 w-full h-1'>
					&nbsp;
				</div>
			</article>

			<section className='pt-22 pb-16 lg:pt-44 lg:pb-32 mx-auto'>
				<article className='max-w-6xl mx-auto'>
					<Features
						heading='Tech for channels'
						subHeading='Give your customers exactly what they want with access to reliable, high-demand events inventory.'
						closingPhrase='The fastest, most reliable way to provide access to quality, vetted live events inventory.'
						data={channels}>
						Tixstock’s market-leading distribution technology lets
						you offer even more to your customers. Tixstock gives
						your customer base unrivalled access to the most
						in-demand events on the market, improving your offering
						to your client base and audience while strengthening
						your brand identity.
						<br /> <br />
						Providing instant access to sold-out and in-demand
						events, Tixstock’s technology lets you add events to
						your e-commerce offering, using powerful, reliable
						tools.
					</Features>
				</article>
			</section>

			<section 
				id='about'
				className='globe py-44 2xl:py-56 mx-auto h-screen lg:h-auto xl:h-screen bg-cover relative flex lg:block items-center px-7 xl:px-0 bg-top md:bg-center'
				style={{
					backgroundImage:
						width > 768
							? 'url(/images/mask-group-40@2x.png)'
							: 'url(/images/earth01.jpg)',
				}}
				ref={globeRef}>

				{/* {width >= 768 ? (
					<video
						muted
						autoPlay
						width='100%'
						className='absolute top-0 h-screen hidden lg:h-auto xl:h-screen lg:block'>
						<source src={EarthVideo} type='video/mp4' />
					</video>
				) : null} */}

				<article className='max-w-6xl mx-auto relative flex items-center h-full'>
					<FullBackground />
				</article>
			</section>
			<section className='p-7 2xl:p-20 2xl:pt-36 2xl:h-[306px] mx-auto bg-black relative overflow-hidden'>
				<div className='absolute top-0 left-0 w-full h-full'></div>
				<Footer />
			</section>
		</div>
	);
}

export default Home;
