import { useContext } from 'react'
import Heading from '../components/Heading';
import SubHeading from '../components/SubHeading';
import Button from './../components/Button';

import AppContext from '../services/AppContext';

function FullBackground() {
	const {
		modal: {setShowModal},
	} = useContext(AppContext);

	return (
		<section className=''>
			<Heading text={['Global reach', <br />, 'Personal service']} />
			<br />
			<SubHeading
				text='Tixstock technology revolutionises the businesses we work with in the live event, ticketing and hospitality industries. Guided by over 40 years of experience managing complex international businesses, our core team recognise the problems businesses face and build tools to solve the challenges of tomorrow.'
				className='text-white max-w-[560px] mb-8'
			/>
			<Button
				text='Find out more'
				theme='light'
				className='inline-block no-height-change text-base'
				onClick={() => setShowModal('contact')}
			/>
		</section>
	);
}

export default FullBackground;
