function HeroPattern({className}) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 1920 1620'
			className={className}>
			<defs>
				<style
					dangerouslySetInnerHTML={{
						__html: '.a{fill:#fff;}.b{clip-path:url(#a);}.d,.e,.f,.g,.h,.i{fill:none;stroke-linecap:square;stroke-miterlimit:10;stroke-width:1.5px;}.e{stroke:#00e500;}',
					}}
				/>
			</defs>
			<g className='b'>
				<g className='c' transform='translate(505.375 3024.351)'>
					<path
						className='d brand-animation animation-delay-2300 fadein'
						d='M1920.375.649,493.988,824.179.375,539.189'
						transform='translate(-505.75 -3025)'
					/>
					{/* <path
						className='d brand-animation animation-delay-2250 fadein'
						d='M1920.375,55.042,517.542,864.973.375,566.386'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-2200 fadein'
						d='M1920.375,109.435,541.094,905.768.375,593.581'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-2150 fadein'
						d='M1920.375,163.829,564.647,946.563.375,620.779'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-2100 fadein'
						d='M1920.375,218.221,588.2,987.357.375,647.974'
						transform='translate(-505.75 -3025)'
					/> */}
					{/* <path
						className='d brand-animation animation-delay-2050 fadein'
						d='M1920.375,272.615,611.753,1028.154.375,675.172'
						transform='translate(-505.75 -3025)'
					/> */}
					<path
						className='d brand-animation animation-delay-2000 fadein'
						d='M1920.375,327,635.305,1068.944.375,702.365'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1950 fadein'
						d='M1920.375,381.4,658.858,1109.741.375,729.564'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1900 fadein'
						d='M1920.375,435.795,682.412,1150.538.375,756.761'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1850 fadein'
						d='M1920.375,490.187,705.964,1191.332.375,783.957'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1800 fadein'
						d='M1920.375,544.581,729.517,1232.127.375,811.153'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1750 fadein'
						d='M1920.375,598.973,753.069,1272.921.375,838.35'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1700 fadein'
						d='M1920.375,653.368,776.627,1313.715.375,865.544'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1650 fadein'
						d='M1920.375,707.759,800.176,1354.51.375,892.742'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1600 fadein'
						d='M1920.375,762.15,823.728,1395.3.375,919.935'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1550 fadein'
						d='M1920.375,816.546,847.282,1436.1.375,947.135'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1500 fadein'
						d='M1920.375,870.936,870.829,1476.89.375,974.33'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1450 fadein'
						d='M1920.375,925.333,894.387,1517.69.375,1001.529'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1400 fadein'
						d='M1920.375,979.725,917.945,1558.48.375,1028.718'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1350 fadein'
						d='M1920.375,1034.117,941.493,1599.278.375,1055.92'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1250 fadein'
						d='M1920.375,1088.511l-955.33,551.563L.375,1083.118'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1200 fadein'
						d='M1920.375,1142.9,988.6,1680.868.375,1110.314'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1150 fadein'
						d='M1920.375,1197.3l-908.224,524.366L.375,1137.511'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1100 fadein'
						d='M1920.375,1251.691l-884.67,510.767L.375,1164.707'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1050 fadein'
						d='M1920.375,1306.084l-861.117,497.169L.375,1191.9'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-1000 fadein'
						d='M1920.375,1360.474,1082.81,1844.041.375,1219.1'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-950 fadein'
						d='M1920.375,1414.869l-814.012,469.973L.375,1246.3'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-850 fadein'
						d='M1920.375,1469.263l-790.459,456.374L.375,1273.492'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-750 fadein'
						d='M1920.375,1523.659l-766.906,442.776L.375,1300.692'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-700 fadein'
						d='M1920.375,1578.05l-743.353,429.178L.375,1327.885'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-650 fadein'
						d='M1920.375,1632.442l-719.8,415.579L.375,1355.082'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-600 fadein'
						d='M1920.375,1686.835l-696.248,401.981L.375,1382.278'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-550 fadein'
						d='M1920.375,1741.228l-672.7,388.383L.375,1409.475'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-500 fadein'
						d='M1920.375,1795.622l-649.139,374.783L.375,1436.669'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-450 fadein'
						d='M1920.375,1850.014,1294.786,2211.2.375,1463.868'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-400 fadein'
						d='M1920.375,1904.408l-602.036,347.587L.375,1491.064'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-350 fadein'
						d='M1920.375,1958.8l-578.483,333.989L.375,1518.258'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-300 fadein'
						d='M1920.375,2013.194l-554.93,320.391L.375,1545.458'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-250 fadein'
						d='M1920.375,2067.583,1389,2374.375.375,1572.65'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-200 fadein'
						d='M1920.375,2121.978l-507.821,293.191L.375,1599.85'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-150 fadein'
						d='M1920.375,2176.372l-484.273,279.6L.375,1627.047'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-100 fadein'
						d='M1920.375,2230.767l-460.719,266L.375,1654.243'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-50 fadein'
						d='M1920.375,2285.159l-437.165,252.4L.375,1681.438'
						transform='translate(-505.75 -3025)'
					/>
					<path
						className='d brand-animation animation-delay-0 fadein'
						d='M1920.375,2339.553l-413.613,238.8L.375,1708.636'
						transform='translate(-505.75 -3025)'
					/>
				</g>
				<path
					className='e last-brand-animation animation-delay-2500 fadein'
					d='M1920.375.649,493.988,824.179.375,539.189'
					transform='translate(-0.375 -0.649)'
				/>
				<path
					className='d brand-opacity-100 brand-animation animation-delay-2250 fadein'
					d='M1920.375,55.042,517.542,864.973.375,566.386'
					transform='translate(-0.375 -0.649)'
				/>
				<path
					className='f brand-opacity-85 brand-animation animation-delay-2200 fadein'
					d='M1920.375,109.435,541.094,905.768.375,593.581'
					transform='translate(-0.375 -0.649)'
				/>
				<path
					className='g brand-opacity-70 brand-animation animation-delay-2150 fadein'
					d='M1920.375,163.829,564.647,946.563.375,620.779'
					transform='translate(-0.375 -0.649)'
				/>
				<path
					className='h brand-opacity-55 brand-animation animation-delay-2100 fadein'
					d='M1920.375,218.221,588.2,987.357.375,647.974'
					transform='translate(-0.375 -0.649)'
				/>
				<path
					className='i brand-animation animation-delay-2050 fadein'
					d='M1920.375,272.615,611.753,1028.154.375,675.172'
					transform='translate(-0.375 -0.649)'
				/>
			</g>
		</svg>
	);
}

export default HeroPattern;
