import {Link} from 'react-router-dom';

function LinkBtn({text, to, theme, size, font, onClick}) {
	let themeText = theme === 'light' ? 'text-white' : '';
	let fontFamily = font === 'body' ? 'font-body' : 'font-heading';
	let textSize =
		size === 'small'
			? 'text-base'
			: size === 'large'
			? 'text-3xl'
			: 'text-xl';
	return (
		<Link
			to={to}
			onClick={onClick}
			className={`${textSize} hover:text-purple-50 ${fontFamily} ${themeText}`}>
			{text}
		</Link>
	);
}

export default LinkBtn;
