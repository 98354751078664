import React from "react";

import TixstockLogo from "../components/TixstockLogo";

const DigitalSignature = () => {
  return (
    <>
      <div className="intern_font px-6 py-6 mx-auto">
        <h2 className="mt-1 font-bold">Gaurav</h2>
        <h2 className="mt-1 font-bold">Developer</h2>
        <p className="mt-1 font-medium">gaurav.mahor@zealousweb.com</p>
        <p className="mt-1 font-medium">
          07800 <span>0000000</span>
        </p>
        <div className="flex items-center mt-4">
          <div>
            <TixstockLogo className="w-52 lg:w-auto" />
          </div>
          <div className="ml-4">
            <h2 className="text-purple-50  font-semibold">
              <a href="#">tixstock.com</a>
            </h2>
            <h2 className="text-gray-600">
              Rue Arnold Winkelried 6, 1201 Geneva, Switzerland
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalSignature;
