import featureBulb from './../assets/icons/featureBulb.svg';
import featureCalender from './../assets/icons/featureCalender.svg';
import featureCheck from './../assets/icons/featureCheck.svg';
import featureEarth from './../assets/icons/featureEarth.svg';
import featureDevide from './../assets/icons/featureDevide.svg';
import featureGrowth from './../assets/icons/featureGrowth.svg';
import featureLogo from './../assets/icons/featureLogo.svg';
import joinIcon from './../assets/icons/joinIcon.svg';

let sellers = [
	{
		id: 1,
		icon: featureDevide,
		text: 'Exchange-agnostic APIs to put your inventory in front of the widest possible audience',
	},
	{
		id: 2,
		icon: featureGrowth,
		text: 'Real-time price controls to ensure you’re reacting instantly to changing demands and volatile markets',
	},
	{
		id: 3,
		icon: featureCheck,
		text: 'Streamlined inventory management to reduce your administration time while giving you complete control of every single listing',
	},
	{
		id: 4,
		icon: featureBulb,
		text: 'Constant innovation to update our tools and technology and give you the features you need, when you need them',
	},
	{
		id: 5,
		icon: featureLogo,
		text: 'Whatever the event, wherever the location, Tixstock makes sure you’re positioned to create and take advantage of demand',
	},
];

let channels = [
	{
		id: 1,
		icon: joinIcon,
		text: 'Simplifies access to a range of options for the most in-demand sports, music and cultural events - generating competition and driving down costs',
	},
	{
		id: 2,
		icon: featureEarth,
		text: 'Delivers a high quality aggregated feed from a global network of suppliers, meaning you aren’t relying on low-volume, irregular sellers',
	},
	{
		id: 3,
		icon: featureCalender,
		text: 'Ensures every single order is fulfilled quickly and on time with fully vetted inventory from the industry’s most trusted suppliers',
	},
	{
		id: 4,
		icon: featureBulb,
		text: 'Continually innovate and evolve to offer your brand exciting new opportunities to improve your offering and access the growing live event market',
	},
	{
		id: 5,
		icon: featureLogo,
		text: 'Whatever the event, wherever the location, Tixstock makes sure you’re positioned to create and take advantage of demand',
	},
];

export {sellers, channels};
