function SubHeading({ text, className, size = 'regular' }) {
	let textSize = '';
	switch (size) {
		case 'lg':
			textSize = 'lg:text-2xl'
			break;
		case 'base':
			textSize = 'text-base'
			break;
		default:
			textSize = 'lg:text-xl'
			break;
	}
	return (
		<h4 className={className + ' text-base font-medium ' + textSize}>
			{text}
		</h4>
	);
}

export default SubHeading;
