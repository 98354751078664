import {useContext} from 'react';
import {motion} from 'framer-motion';

import Menu from './Menu';
import Contact from '../pages/Contact';
import AppContext from '../services/AppContext';

function Modal() {
	const {
		modal: {showModal},
	} = useContext(AppContext);
	return (
		<>
			{showModal ? (
				<motion.div
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.3}}
					className='overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none bg-purple-200 xl:pb-0 pb-0'>
					{showModal === 'menu' ? <Menu /> : null}
					{showModal === 'contact' ? <Contact /> : null}
				</motion.div>
			) : null}
		</>
	);
}

export default Modal;
