import axios from "axios";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import CtaBtn from "./../components/CtaBtn";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
// React-select Component
const options = [
  // {  value: '', label: 'Your location'},
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "France", label: "France" },
  { value: "Germany", label: "Germany" },
  { value: "Italy", label: "Italy" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Singapore", label: "Singapore" },
  { value: "South Africa", label: "South Africa" },
  { value: "Spain", label: "Spain" },
  { value: "Qatar", label: "Qatar" },
  { value: "UAE", label: "United Arab Emirates" },
  { value: "USA", label: "United States" },
];


// Validation Schema
const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company_name: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  location:Yup.object().shape({
    label: Yup.string().required("Is required"), 
    value: Yup.string().required("Is required")
  }),
  email_address: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string(),
});

function ContactForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const UNAME = process.env.REACT_APP_API_USERNAME;
  const PASSWD = process.env.REACT_APP_API_PASSWORD;
  const [resStatus, setResStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [res, setRes] = useState();
  const ref = useRef()
  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email_address: "",
      company_name: "",
      phone_number: "",
      message: "",
      location: {value: "", label: "Your location"},
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      let location = values.location.value

      setSubmitting(true);
      var config = {
        method: "post",
        url: API_URL,
        auth: {
          username: UNAME,
          password: PASSWD,
        },
        headers: {
          "Content-Type": "application/json",
        },
        data: {...values,location},
      };

      axios(config)
        .then(function (response) {
          if(response.status === 200 && response.data){
              if(response.data?.message){
                setRes(
                  "Your request has been submitted successfully. We'll reach out to you soon!"
                );
                } 
                else if (response.data?.errors) {
                  setRes(response.data.errors);
                }
                setSubmitting(false);
            }
          }
          )
        .catch(function (error) {
          setSubmitting(false);
          console.log(error);
        }).finally(function (){
          setSubmitting(false);
          formik.resetForm()
        })
    },
  });

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
          ? "#6F6CFF"
          : formik.errors.location
          ? "red"
          : "",
        "&:hover": {
          borderColor: state.isFocused ? "#6F6CFF" : "border-gray-200",
        },
      };
    },
    singleValue: (provided, sas) => {
      if (sas.children === "Your location") {
        return {
          ...provided,
          color: "rgba(0,0,0,.5)",
        };
      } else {
        return {
          ...provided,
          color: "black",
        };
      }
    },
  };

  return (
    <div className="w-full p-7 lg:p-10 pt-8 rounded-lg bg-white contact-form">
      {resStatus ? (
        <div className="bg-green-200 p-5 rounded-lg">
          Thanks for contacting us, you will be hearing from us soon
        </div>
      ) : (
        // <form onSubmit={handleSubmit(onSubmit)}>
        <form onSubmit={formik.handleSubmit}>
        <div className="mb-5">
          <ul className="mb-3">
            {Array.isArray(res) ? (
              res.map((item) => {
                return (
                  <>
                    <li className="text-red-400">{item}</li>
                  </>
                );
              })
            ) : (
              <li className="text-green-600">{res}</li>
            )}
          </ul>

          {/* {/ name /} */}
          <div className="relative">
            <input
              type="text"
              id="name"
              aria-describedby="name_help"
              className={`block w-full py-2 lg:py-3 px-5 text-gray-900 bg-transparent rounded border ${
                formik.errors.name ? "border-red-500" : "border-gray-200"
              } focus:border-purple-50 focus:ring-0 focus:outline-none peer`}
              placeholder=" "
              // {...register('name', {required: true})}
              {...formik.getFieldProps("name")}
            />

            <label
              htmlFor="name"
              className="absolute opacity-50 border-gray-200 duration-300 transform -translate-y-4 scale-90 top-1 z-10 origin-[0] bg-white px-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:px-3 peer-focus:left-3 peer-focus:text-purple-50 peer-focus:opacity-100 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Your Name
            </label>
          </div>
          {/* {formik.errors.name && formik.touched.name ? (
            <span className="text-sm text-danger">
              Please enter a valid name
            </span>
          ) : null} */}
        </div>

        {/* {/ company name /} */}
        <div className="mb-5">
          <div className="relative">
            <input
              type="text"
              id="company_name"
              aria-describedby="company_name_help"
              className={`block w-full py-2 lg:py-3 px-5 text-purple-400 bg-transparent rounded border ${
                formik.errors.company_name
                  ? "border-red-500"
                  : "border-gray-200"
              } focus:border-purple-50 focus:ring-0 focus:outline-none peer`}
              placeholder=" "
              // {...register('company_name', {required: true})}
              {...formik.getFieldProps("company_name")}
            />
            <label
              htmlFor="company_name"
              className="absolute opacity-50 border-gray-200 duration-300 transform -translate-y-4 scale-90 top-1 z-10 origin-[0] bg-white px-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:px-3 peer-focus:left-3 peer-focus:text-purple-50 peer-focus:opacity-100 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Company Name
            </label>
          </div>
          {/* {formik.errors.company_name && formik.touched.company_name ? (
            <span className="text-sm text-danger">
              Please enter your company name
            </span>
          ) : null} */}
        </div>

        {/* {/ drop down /} */}
        <div className='mb-5 relative dropdown after:content-[""] after:absolute lg:after:top-[22px] after:top-[17px] after:right-5 after:w-[7px] after:h-[7px] after:border after:border-t-0 after:border-r-0  after:border-black after:-rotate-45 after:pointer-events-none '>
          <Select
            options={options}
            id="location"
            name="location"
            ref={ref}
            value={formik.values.location}
            styles={customStyles}
            // {...formik.getFieldProps('location')}
            onChange={(data) => {
              formik.setFieldValue("location", data);
            }}
            // components={ValueContainer}
            // {...register('location', {required: true})}
          />
          {/* {formik.errors.location && formik.touched.location ? (
            <span className="text-sm text-danger">
              Please select your location
            </span>
          ) : null} */}
        </div>

        {/* {/ Phone Number /} */}
        <div className="mb-5">
          <div className="relative">
            <input
              type="tel"
              id="phone_number"
              aria-describedby="phone_number_help"
              className={`block w-full py-2 lg:py-3 px-5 text-gray-900 bg-transparent rounded border ${
                formik.errors.phone_number
                  ? "border-red-500"
                  : "border-gray-200"
              } focus:border-purple-50 focus:ring-0 focus:outline-none peer`}
              placeholder=" "
              // {...register('phone_number', {required: true})}
              {...formik.getFieldProps("phone_number")}
            />
            <label
              htmlFor="phone_number"
              className="absolute opacity-50 border-gray-200 duration-300 transform -translate-y-4 scale-90 top-1 z-10 origin-[0] bg-white px-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:px-3 peer-focus:left-3 peer-focus:text-purple-50 peer-focus:opacity-100 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Phone Number
            </label>
          </div>
          {/* {formik.errors.phone_number && formik.touched.phone_number ? (
            <span className="text-sm text-danger">
              Please enter a valid phone number
            </span>
          ) : null} */}
        </div>

        {/* {/ email addreess /} */}
        <div className="mb-5">
          <div className="relative">
            <input
              type="email"
              id="email_address"
              aria-describedby="email_address_help"
              className={`block w-full py-2 lg:py-3 px-5 text-gray-900 bg-transparent rounded border ${
                formik.errors.email_address
                  ? "border-red-500"
                  : "border-gray-200"
              } focus:border-purple-50 focus:ring-0 focus:outline-none peer`}
              placeholder=" "
              // {...register('email_address', {required: true})}
              {...formik.getFieldProps("email_address")}
            />
            <label
              htmlFor="email_address"
              className="absolute opacity-50 border-gray-200 duration-300 transform -translate-y-4 scale-90 top-1 z-10 origin-[0] bg-white px-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:px-3 peer-focus:left-3 peer-focus:text-purple-50 peer-focus:opacity-100 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Email Address
            </label>
          </div>
          {/* {formik.errors.email_address && formik.touched.email_address ? (
            <span className="text-sm text-danger">
              Please enter a valid email
            </span>
          ) : null} */}
        </div>

        {/* {/ message /} */}
        <div className="mb-5">
          <div className="relative">
            <textarea
              id="message"
              rows="3"
              aria-describedby="message_help"
              className="block w-full py-2 lg:py-3 px-5 text-gray-900 bg-transparent rounded border border-gray-200 focus:border-purple-50 focus:ring-0 focus:outline-none peer"
              placeholder=" "
              // {...register('message')}
              {...formik.getFieldProps("message")}
            ></textarea>
            <label
              htmlFor="message"
              className="absolute opacity-50 border-gray-200 duration-300 transform -translate-y-4 scale-90 top-1 z-10 origin-[0] bg-white px-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-5 peer-focus:px-3 peer-focus:left-3 peer-focus:text-purple-50 peer-focus:opacity-100 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Message
            </label>
          </div>
        </div>

        <CtaBtn text="Send" submitting={submitting} />
      </form>
      )}
    </div>
  );
}

export default ContactForm;
