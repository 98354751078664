
function Hamburger() {
  return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={14}
			height='9.5'
			viewBox='0 0 14 9.5'>
			<g transform='translate(-324 -37.25)'>
				<line
					y2={14}
					transform='translate(338 42) rotate(90)'
					fill='none'
					stroke='#fff'
					strokeWidth='1.5'
				/>
				<line
					y2={14}
					transform='translate(338 38) rotate(90)'
					fill='none'
					stroke='#fff'
					strokeWidth='1.5'
				/>
				<line
					y2={14}
					transform='translate(338 46) rotate(90)'
					fill='none'
					stroke='#fff'
					strokeWidth='1.5'
				/>
			</g>
		</svg>
  );
}

export default Hamburger