function CtaBtn({text, submitting}) {
	let style = submitting ? 'disabled:opacity-75 disabled:hover:bg-purple-50 ' : '';
	return (
		<button
			type='submit'
			className={
				'bg-purple-50 block text-center rounded font-heading text-xl text-white w-full px-5 py-3 hover:bg-brand hover:border-brand ' +
				style
			}
			disabled={submitting}>
			{submitting ? (
				<svg
					className='animate-spin mx-auto h-5 w-5 text-white'
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'>
					<circle
						className='opacity-25'
						cx='12'
						cy='12'
						r='10'
						stroke='currentColor'
						stroke-width='4'></circle>
					<path
						className='opacity-75'
						fill='currentColor'
						d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'></path>
				</svg>
			) : (
				text
			)}
		</button>
	);
}

export default CtaBtn;
