import {useContext} from 'react';
import {motion} from 'framer-motion';
import { Link } from 'react-router-dom';

import Logo from '../components/Logo';
import Button from '../components/Button';
import Heading from '../components/Heading';
import SubHeading from '../components/SubHeading';
import ContactForm from '../layouts/ContactForm';

import contactPattern from './../assets/icons/contactPattern.svg';
import cross from './../assets/icons/cross.svg';

import AppContext from '../services/AppContext';

function Contact() {
	const {
		modal: {setShowModal},
		scroll: { scrollPosition },
	} = useContext(AppContext);

	// setup for header class
	let headerClass = 'border-b-transparent z-50'

	//setting up different style based on scroll position
	if (scrollPosition > 10) {
		headerClass = '2xl:py-10 scrolled'
	}

	return (
		<div className='bg-purple-200 lg:h-auto contact-pop'>
			<div className='relative sm:h-full lg:h-auto overflow-hidden'>
				<motion.img
					className='absolute top-0 bottom-[-40%] right-[-220%] sm:right-[-90%] lg:bottom-auto lg:left-0 z-10 max-w-[400%] lg:w-full h-full hidden'
					src={contactPattern}
					alt=''
					initial={{opacity: 0.5}}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.5}}
				/>
				<section className={`${headerClass} contact-pop-header p-7 md:p-10 2xl:p-20 2xl:pb-10 mx-auto relative z-20`}>
					<div className='flex items-center'>
						<Link
							className='w-52'
							onClick={() => setShowModal('menu')}>
							<Logo className='w-52 lg:w-auto' />
						</Link>
						<Button
							to='/'
							theme='light'
							className='ml-auto px-2 w-[36px] flex'
							onClick={() => setShowModal(false)}>
							<img src={cross} alt='' />
						</Button>
					</div>
				</section>
				<motion.section
					className='relative z-20 px-4 2xl:px-20 2xl:pb-40 mx-auto contact-pop-content'
					initial={{opacity: 0.5}}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.5}}>
					<div className='max-w-6xl mx-auto'>
						<div className='lg:flex items-center pb-8 lg:pb-0'>
							<article className='lg:w-2/4 mt-14 lg:mt-0 px-4 lg:px-0'>
								<Heading
									text={'Talk to an expert'}
									className='mb-5'
									theme='light'
								/>
								<SubHeading
									text='Please fill out the form to connect with one of our experts and begin your Tixstock journey.'
									className='text-white max-w-md mb-7'
								/>
							</article>
							<article className='lg:w-[49%] ml-auto'>
								<ContactForm />
							</article>
						</div>
					</div>
				</motion.section>
			</div>
		</div>
	);
}

export default Contact;
