// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';

// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';

function Carousel({data,setAddClass}) {


	// function add_class(e) {
	// 	document.getElementById('slide_wrap').classList.add("mystyle");}
	// } 
		
	const add_class = (e) => {
		console.log("e",e);
		const addStyle = e?.activeIndex !== 0? "mystyle": "";
		setAddClass(addStyle)
	}

	return (
		<div className=''>
			<Swiper
				slidesPerView={'auto'}
				// slidesPerView= {2.7}
				spaceBetween={30}
				navigation={true}
				modules={[Navigation]}
				// centeredSlides={true}
				onSlideChange={(e) => add_class(e)}
				// onSwiper={(swiper) => add_class()}
				breakpoints={{
					// 1024: {
					// 	slidesPerView: 2
					// },
					768: {
						spaceBetween: 40,
					},
				}}
				className='mySwiper'>
				{data.map((item) => (
					<SwiperSlide key={item.id}>
						<img className='mb-24 w-12' src={item.icon} alt='' />
						{item.text}
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}

export default Carousel;
