function HeroPatternMobile({className}) {

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='100%'
			viewBox='0 0 376 1624'
			className={className}>
			<defs>
				<clipPath id='a'>
					<rect width={376} height={1624} fill='#fff' />
				</clipPath>
			</defs>
			<g clipPath='url(#a)'>
				<g transform='translate(85.375 2774.351)'>
					<path
						d='M1920.375.649,493.988,824.179.375,539.189'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-2150'
					/>
					<path
						d='M1920.375,55.042,517.542,864.973.375,566.386'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-2100'
					/>
					<path
						d='M1920.375,109.435,541.094,905.768.375,593.581'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-2050'
					/>
					<path
						d='M1920.375,163.829,564.647,946.563.375,620.779'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-2000'
					/>
					<path
						d='M1920.375,218.221,588.2,987.357.375,647.974'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1950'
					/>
					<path
						d='M1920.375,272.615,611.753,1028.154.375,675.172'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1900'
					/>
					<path
						d='M1920.375,327,635.305,1068.944.375,702.365'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1850'
					/>
					<path
						d='M1920.375,381.4,658.858,1109.741.375,729.564'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1800'
					/>
					<path
						d='M1920.375,435.795,682.412,1150.538.375,756.761'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1750'
					/>
					<path
						d='M1920.375,490.187,705.964,1191.332.375,783.957'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1700'
					/>
					<path
						d='M1920.375,544.581,729.517,1232.127.375,811.153'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1650'
					/>
					<path
						d='M1920.375,598.973,753.069,1272.921.375,838.35'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1600'
					/>
					<path
						d='M1920.375,653.368,776.627,1313.715.375,865.544'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1550'
					/>
					<path
						d='M1920.375,707.759,800.176,1354.51.375,892.742'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1500'
					/>
					<path
						d='M1920.375,762.15,823.728,1395.3.375,919.935'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1450'
					/>
					<path
						d='M1920.375,816.546,847.282,1436.1.375,947.135'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1400'
					/>
					<path
						d='M1920.375,870.936,870.829,1476.89.375,974.33'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1350'
					/>
					<path
						d='M1920.375,925.333,894.387,1517.69.375,1001.529'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1300'
					/>
					<path
						d='M1920.375,979.725,917.945,1558.48.375,1028.718'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1250'
					/>
					<path
						d='M1920.375,1034.117,941.493,1599.278.375,1055.92'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1200'
					/>
					<path
						d='M1920.375,1088.511l-955.33,551.563L.375,1083.118'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1150'
					/>
					<path
						d='M1920.375,1142.9,988.6,1680.868.375,1110.314'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1100'
					/>
					<path
						d='M1920.375,1197.3l-908.224,524.366L.375,1137.511'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1050'
					/>
					<path
						d='M1920.375,1251.691l-884.67,510.767L.375,1164.707'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-1000'
					/>
					<path
						d='M1920.375,1306.084l-861.117,497.169L.375,1191.9'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-950'
					/>
					<path
						d='M1920.375,1360.474,1082.81,1844.041.375,1219.1'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-900'
					/>
					<path
						d='M1920.375,1414.869l-814.012,469.973L.375,1246.3'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-850'
					/>
					<path
						d='M1920.375,1469.263l-790.459,456.374L.375,1273.492'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-800'
					/>
					<path
						d='M1920.375,1523.659l-766.906,442.776L.375,1300.692'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-750'
					/>
					<path
						d='M1920.375,1578.05l-743.353,429.178L.375,1327.885'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-700'
					/>
					<path
						d='M1920.375,1632.442l-719.8,415.579L.375,1355.082'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-650'
					/>
					<path
						d='M1920.375,1686.835l-696.248,401.981L.375,1382.278'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-600'
					/>
					<path
						d='M1920.375,1741.228l-672.7,388.383L.375,1409.475'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-550'
					/>
					<path
						d='M1920.375,1795.622l-649.139,374.783L.375,1436.669'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-500'
					/>
					<path
						d='M1920.375,1850.014,1294.786,2211.2.375,1463.868'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-450'
					/>
					<path
						d='M1920.375,1904.408l-602.036,347.587L.375,1491.064'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-400'
					/>
					<path
						d='M1920.375,1958.8l-578.483,333.989L.375,1518.258'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-350'
					/>
					<path
						d='M1920.375,2013.194l-554.93,320.391L.375,1545.458'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-300'
					/>
					<path
						d='M1920.375,2067.583,1389,2374.375.375,1572.65'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-250'
					/>
					<path
						d='M1920.375,2121.978l-507.821,293.191L.375,1599.85'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-200'
					/>
					<path
						d='M1920.375,2176.372l-484.273,279.6L.375,1627.047'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-150'
					/>
					<path
						d='M1920.375,2230.767l-460.719,266L.375,1654.243'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-100'
					/>
					<path
						d='M1920.375,2285.159l-437.165,252.4L.375,1681.438'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-50'
					/>
					<path
						d='M1920.375,2339.553l-413.613,238.8L.375,1708.636'
						transform='translate(-505.75 -3025)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='brand-animation animation-delay-0'
					/>
				</g>
				<g transform='translate(-420 -250)'>
					<path
						d='M1920.375.649,493.988,824.179.375,539.189'
						transform='translate(-0.375 -0.649)'
						fill='none'
						stroke='#00d900'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='e last-brand-animation animation-delay-2250 fadein first-line'
					/>
					<path
						d='M1920.375,55.042,517.542,864.973.375,566.386'
						transform='translate(-0.375 -0.649)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='opacity-60 brand-animation animation-delay-2100'
					/>
					<path
						d='M1920.375,109.435,541.094,905.768.375,593.581'
						transform='translate(-0.375 -0.649)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='opacity-[.45] brand-animation animation-delay-2050'
					/>
					<path
						d='M1920.375,163.829,564.647,946.563.375,620.779'
						transform='translate(-0.375 -0.649)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='opacity-30 brand-animation animation-delay-2000'
					/>
					<path
						d='M1920.375,218.221,588.2,987.357.375,647.974'
						transform='translate(-0.375 -0.649)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='opacity-[.15] brand-animation animation-delay-1950'
					/>
					<path
						d='M1920.375,272.615,611.753,1028.154.375,675.172'
						transform='translate(-0.375 -0.649)'
						fill='none'
						strokeLinecap='square'
						strokeMiterlimit={10}
						strokeWidth='1.5'
						className='opacity-0 brand-animation animation-delay-1900'
					/>
				</g>
			</g>
		</svg>
	);
}

export default HeroPatternMobile;
