import {useContext} from 'react';
import {Link} from 'react-router-dom';
import Button from '../components/Button';
import LinkBtn from '../components/LinkBtn';
import Hamburger from '../components/Hamburger';
import Logo from '../components/Logo';

import cross from './../assets/icons/cross.svg';

import scrollIntoView from './../utils/scrollIntoView';
import AppContext from '../services/AppContext';

function Navbar() {
	const {
		modal: {setShowModal},
	} = useContext(AppContext);
	const url = window.location.pathname;
	let width = window.innerWidth;
	return (
		<>
			<nav className='text-white relative z-20'>
				<ul className='flex items-center'>
					<li className='w-52 md:w-auto'>
						<Link to='/' onClick={() => scrollIntoView('pageTop')}>
							<Logo className='w-52 lg:w-auto' />
						</Link>
					</li>
					{url !== '/contact' ? (
						<>
							{width < 769 ? (
								<Button
									to='/'
									theme='light'
									className='ml-auto px-2 py-2 flex hamburger'
									onClick={() => setShowModal('menu')}>
									<Hamburger />
								</Button>
							) : (
								<>
									<li className='ml-auto menu-item'>
										<LinkBtn
											text='Sellers'
											theme='light'
											onClick={() =>
												scrollIntoView('sellers')
											}
										/>
									</li>
									<li className='menu-item'>
										<LinkBtn
											text='Channels'
											theme='light'
											onClick={() =>
												scrollIntoView('channels')
											}
										/>
									</li>
									<li className='menu-item'>
										<LinkBtn
											text='About'
											theme='light'
											onClick={() =>
												scrollIntoView('about')
											}
										/>
									</li>
									<li>
										<Button
											text='Contact'
											theme='light'
											className='flex menu-item button'
											onClick={() =>
												setShowModal('contact')
											}
										/>
									</li>
								</>
							)}
						</>
					) : (
						<Button
							to='/'
							theme='light'
							className='ml-auto px-2 lg:py-2 flex'>
							<img className='lg:h-[17.6px]' src={cross} alt='' />
						</Button>
					)}
				</ul>
			</nav>
		</>
	);
}
export default Navbar;
