function TixstockLogo({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      <g id="Group_2970" data-name="Group 2970" transform="translate(-30 -136)">
        <g
          id="Group_2968"
          data-name="Group 2968"
          transform="translate(-164 115)"
        >
          <circle
            id="Ellipse_1"
            data-name="Ellipse 1"
            cx="26"
            cy="26"
            r="26"
            transform="translate(194 21)"
            fill="#41007f"
          />
          <path
            id="Path_1202"
            data-name="Path 1202"
            d="M26.867,30.943l3.479-2.008,3.478,2.008-3.478,2.008Zm-9.833,2.008V25.266l6.656-3.843L37,29.109,40.48,27.1,23.69,17.407,13.556,23.258v7.685Zm6.656-7.86L20.212,27.1V42.472l10.134,5.851L40.48,42.472V38.455L30.346,44.306,23.69,40.463V29.109L27.168,27.1Zm3.178,9.52v4.017l3.479,2.008,16.789-9.694V23.258L37,17.407l-3.479,2.008,10.134,5.851v3.67L30.346,36.621Z"
            transform="translate(189.654 15.419)"
            fill="#00e500"
          />
        </g>
      </g>
    </svg>
  );
}

export default TixstockLogo;
