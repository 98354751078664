import H3 from './../components/H3';

function ShortFeature({icon, heading, children}) {
	return (
		<article className='text-center md:w-2/4 lg:w-80 mx-auto md:mb-0 mb-10'>
			<img src={icon} alt={heading} className='mx-auto md:mb-6 mb-4' />
			<H3 text={heading} className='text-white md:mb-7 mb-5' />
			{children}
		</article>
	);
}

export default ShortFeature;
